require('normalize.css/normalize.css');
require('./styles/index.scss');
require('lazysizes/lazysizes.js');

var $ = require('jquery');

import emergence from "emergence.js";

document.addEventListener("DOMContentLoaded", () => {


    const html = document.querySelector('html');

    setTimeout(function() {
        $('#header-menu').addClass('transform');
    }, 500);

    // emergence
    emergence.init({
        container: window,
        reset: true,
        throttle: 0,
        elemCushion: 0.15,
        offsetTop: 0,
        offsetBottom: 0
    });

    // Scroll To Element Smothy with Vanilla JavaScript
    var offset = 0;
    var call;
    function scroll(target) {
        $('html, body').animate(
            { scrollTop: target }, 1000
        );
    }

    var serialize = function (form) {

        var serialized = [];

        for (var i = 0; i < form.elements.length; i++) {

            var field = form.elements[i];

            if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue;

            if (field.type === 'select-multiple') {
                for (var n = 0; n < field.options.length; n++) {
                    if (!field.options[n].selected) continue;
                    serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.options[n].value));
                }
            } else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
                serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value));
            }
        }

        return serialized.join('&');

    };

    // menu
    function anchorLink(e) {
        e.preventDefault();
        if ($('html').hasClass('home')) {
            
            var id = this.href.replace(/^.*#/gi, '#');
            var target = parseInt($(id).offset().top) - 50;
            menu.setAttribute('aria-hidden', true);
            html.setAttribute('data-menu', 'closed');
            scroll(target);
            
        } else {

            window.location.href = this.href;

        }
    }

    function toggleMenu() {
        if (html.getAttribute('data-menu') === 'closed') {
            menu.removeAttribute('aria-hidden');
            html.setAttribute('data-menu', 'open');
        } else {
            menu.setAttribute('aria-hidden', true);
            html.setAttribute('data-menu', 'closed');
        }
    }

    $('.js_menu').click(toggleMenu);
    var menu = document.querySelector('#header-menu');
    var menuLinks = document.querySelectorAll('.menu-link');

    for (let index = 0; index < menuLinks.length; index++) {
        menuLinks[index].addEventListener('click', anchorLink);
    }



    // intro - scroll
    if (document.getElementsByClassName('js_scroll-down').length) {
        $('.js_scroll-down').click(function() {
            var target = parseInt(
                $('#scrollTarget').offset().top
            );
            scroll(target);
        });
    }


    $('.js_scroll-top').click(function() {
        scroll(0);
    });


    // text - read more
    if (document.getElementsByClassName('js_read-more').length) {
        var readMore = document.getElementsByClassName('js_read-more')[0];
        readMore.onclick = () => {
            document.querySelector('.text--read-more').removeAttribute('aria-hidden');
            readMore.setAttribute('aria-hidden', true);
        };
    }


    // show/hide sticky element
    if (document.getElementsByClassName('sticky').length) {

        var sticky = document.getElementsByClassName('sticky')[0];

        setInterval(() => {

            if (window.pageYOffset >= window.innerHeight) {
                sticky.removeAttribute('aria-hidden');
            } else {
                sticky.setAttribute('aria-hidden', true);
            }

        }, 1000);

    }


    // sticky logo
    if (document.querySelector('.intro .logo')) {
        
        var logo = document.querySelector('.intro .logo');
        var logoIsSticky = false;

        setInterval(() => {
            if (!logoIsSticky && window.pageYOffset > window.innerHeight) {

                logo.classList.add('logo--sticky');
                logoIsSticky = true;

            } else if (logoIsSticky && window.pageYOffset <= window.innerHeight) {

                logo.classList.remove('logo--sticky');
                logoIsSticky = false;

            }
        }, 500);

    }


    // form
    if (document.getElementsByClassName('js_submit-form').length) {
        
        function checkInputValue() {
            if (this.value != '') {
                this.setAttribute('value', this.value);
            } else {
                this.removeAttribute('value');
            }
        }
        
        // var submitForm = document.querySelector('.js_submit-form');
        var inputFields = document.querySelectorAll('input[type=text], input[type=tel], input[type=email], textarea');
    
        for (let index = 0; index < inputFields.length; index++) {
            inputFields[index].addEventListener('change', checkInputValue);
        }
    
        $('#contact-form').submit(function(e) {

            e.preventDefault();
            var form = $(this);

            $.ajax({
                type: "POST",
                url: form.attr("action"),
                dataType: "json",
                data: {
                    action: 'anfrage',
                    form: form.serializeArray()
                }
            })
            .done(function(response) {

                if (response === 'sent') {

                    $('#contact-form').attr('aria-hidden', true);
                    $('.text--thank-you').removeAttr('aria-hidden');

                } else {

                    console.error(response);

                }

            });
        });

    }


    // image slide shows
    if (document.querySelectorAll('.image--multiple .image-wrapper').length) {
        function initSlideshow(nodeList) {
            var index = 1; // start with second image
            var maxIndex = nodeList.children.length-1;
    
            setInterval(() => {
                if (index <= 0) {
                    nodeList.children[maxIndex].classList.remove('active');
                } else {
                    nodeList.children[index-1].classList.remove('active');
                }
    
                nodeList.children[index].classList.add('active');
    
                if (index < maxIndex) {
                    index += 1;
                } else {
                    index = 0;
                }
            }, 4500);
        }
    
        var slideShows = document.querySelectorAll('.image--multiple .image-wrapper');
        for (let index = 0; index < slideShows.length; index++) {
            initSlideshow(slideShows[index]);
        }
    }


});
